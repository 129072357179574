import Completionist from "react-countdown";
import Countdown from "react-countdown";
import React, { Component } from 'react'
import './InvitacionWilmerYMary.scss'
import { ReactSVG } from 'react-svg'
import svgRosa from "./src/icons/Rosa.svg";
import svgAro from "./src/icons/aro.svg";
import svgAnillos from "./src/icons/anillos.svg";
import svgSoundOn from "./src/icons/speaker_Icon.svg";
import svgSoundOff from "./src/icons/mute_Icon.svg";

import PropagateLoader from "react-spinners/PropagateLoader";
import Helmet from 'react-helmet'
import SlideHeaderInvitacionWM from "./SlideHeaderInvitacionWM";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import request from "superagent";
import { nuevoMensaje, tiposAlertas } from "../../inicialized/Toast";
import FondoActions from "../FondoActions";
import { motion } from "framer-motion";
import { WIVOpacityLeft50, WIVOpacityOnly, WIVOpacityRigth50, WIVOpacityUp50, WIVScaleOnly13, WIVScaleOnly8, WIVScaleOut13, initialOpacityLeft50, initialOpacityOnly, initialOpacityRigth50, initialOpacityUp50, initialScaleOnly13, initialScaleOnly8, initialScaleOut13 } from "../../inicialized/Transitions";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import video from "./src/video/video-frase4.mp4";
import videoMusic from "./src/video/video-frase3-m.mp4";
import audioMusic from './src/audio/CarlosRivera-LaCarta.mp3'


const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const tracks = [
  {
    url: "https://www.feegosystem.com/srcAltezza/audio/Carlos Rivera - La Carta.mp3",
    title: "Nuewstra boda",
  }
];




export default class Invitacion extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      invitacion: null,
      listaInvitados: null,
      invitadoInvitacion: null,
      isPlaying: false,
      sobreAbierto: false,
      audioBack: new Audio('https://www.feegosystem.com/srcAltezza/audio/CarlosRivera-LaCarta.mp3')
    }


  }



  componentDidMount() {
    this.getInvitacion()
  }

  playPause() {

    // Get state of song
    let isPlaying = this.state.isPlaying;

    if (isPlaying) {
      this.state.audioBack.pause()
    } else {
      this.state.audioBack.play()
      setTimeout(() =>
        this.setState({
          sobreAbierto: true
        })
        , 1900)

    }

    this.setState({ isPlaying: !isPlaying });
  };

  getInvitado() {
    const lista = this.state.listaInvitados
    if (lista.length > 0) {
      lista.map((inv) => {
        if (inv.id == this.props.idInvitado) {
          this.setState({
            invitadoInvitacion: inv
          })
        }
      })
    }

  }

  getInvitacion() {
    request
      .post('/responseAltezza/eventoXinvitacion')
      .send({ idInvitacion: this.props.idInvitacion }) // sends a JSON post body
      .set('accept', 'json')
      .end((err, res) => {

        if (err) {
          nuevoMensaje(tiposAlertas.cargadoError, "Error al cargar invitación, intenta de nuevo en unos minutos por favor: " + err);
        } else {
          const respuestaLogin = JSON.parse(res.text);
          this.setState({
            loading: false,
            invitacion: respuestaLogin,
            listaInvitados: respuestaLogin.listaInvitados
          }, () => {
            this.getInvitado()
          })
        }


      });
  }

  async updateConfirmado() {
    nuevoMensaje(tiposAlertas.cargando, "Confirmando invitaciones");
    const result = await Promise.all(this.state.listaInvitados.map(async (invitado) => {
      return new Promise((resolve, reject) => {
        request
          .post('/responseAltezza/updConfirmado')
          .send({ idInvitado: invitado.id, confirmado: invitado.confirmado }) // sends a JSON post body
          .set('accept', 'json')
          .end((err, res) => {

            if (err) {
              nuevoMensaje(tiposAlertas.cargadoError, "Error al confirmar invitaciones, por favor intenta en unos minutos");
            } else {
              nuevoMensaje(tiposAlertas.cargadoSuccess, "Invitaciones confirmadas, recuerda que siempre podrás confirmar tu asistencia en esta página", 5000);
              resolve(res)
            }
          });
      })
    }))


  }

  rendererCountDown({ days, hours, minutes, seconds, completed }) {
    if (completed) {
      // Render a completed state
      return <span>Es hoy !</span>;
    } else {
      // Render a countdown
      return (
        <div className="content">
          <h3>Solo faltan </h3>
          <span><strong>{days}</strong>  Días :  <strong>{hours}</strong> Horas  :  <strong>{minutes}</strong> Minutos  :  <strong>{seconds}</strong>  Seg</span>
          <h3>Para nuestra boda </h3>
        </div>
      );
    }
  };

  renderLoading() {


    if (this.state.loading == true) {
      return ([
        <FondoActions />,
        <div className="cargando">

          <img src={require('../../images/logo_altezza_eventos_inolvidables.png')} alt="" />
          <span>Cargando invitación</span>
          <PropagateLoader
            color={"#EFD3C5"}
            loading={this.state.loading}
            cssOverride={override}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ])

    } else {
      const urlvideo = 'https://www.youtube.com/embed/ynJ_QU_FU7Q?si=PZGhly6TDzki6KNo&amp;controls=0&amp;start=5&amp;autoplay=1'
      const invitacion = this.state.invitacion
      const fecha = new Date(invitacion.fechaHoraCeremonia).toLocaleDateString('es-es', { weekday: "long", year: "numeric", month: "long", day: "numeric" })
      const horaCeremonia = new Date(invitacion.fechaHoraCeremonia).toLocaleString('es-es', { hour: 'numeric', minute: 'numeric', hour12: true })
      const horaCelebracion = new Date(invitacion.fechaHoraRecepcion).toLocaleString('es-es', { hour: 'numeric', minute: 'numeric', hour12: true })
      return (

        <div className="contentContainerWYM">

          {!this.state.sobreAbierto &&
            <div className={this.state.isPlaying ? "envelop envelopOpen" : "envelop"} >
              <div className="tapa">
                <div className="sello">
                  <img src={require('./src/images/fondos/lacre-sello1.png')} alt="" />
                  <span className="abrir" onClick={() => this.playPause()}>Abrir</span>
                </div>
              </div>
              <div className="base" />
            </div>
          }

          <motion.div initial={initialScaleOnly13} whileInView={WIVScaleOnly13} viewport={{ once: true }} >
            <div className="sectionInv header">

            </div>
          </motion.div>

          <motion.div initial={initialScaleOnly8} whileInView={WIVScaleOnly8} viewport={{ once: true }} >
            <div className="sectionLogo">
              <img src="https://www.feegosystem.com/srcAltezza/images/invitaciones/WYM/logo_manual1.png" alt="" />
            </div>
          </motion.div>

          <div className="sectionInv sectionNom bienvenida">

            <motion.div initial={initialOpacityOnly} whileInView={WIVOpacityOnly} viewport={{ once: true }} >
              <div className="sectionInv fraseIntro">

                <video autoplay={'autoplay'} muted loop>
                  <source src={videoMusic} type="video/mp4" />
                </video>

                <div className="info">
                  <span>¡La confirmación de un Si!</span>
                </div>

              </div>
            </motion.div>

            <motion.div initial={initialOpacityLeft50} whileInView={WIVOpacityLeft50} viewport={{ once: true }} >

              {invitacion.mensaje != '' ?
                <h2 style={{ color: '#0e347d' }}>{invitacion.mensaje}</h2>
                :
                null
              }

              {this.state.invitadoInvitacion && invitacion.mensaje == '' ?
                <h2 style={{ color: '#0e347d' }}>{this.state.invitadoInvitacion.nombre}</h2>
                :
                null
              }

              {this.state.invitadoInvitacion && invitacion.mensaje == '' && !this.state.invitadoInvitacion ?
                <h2 style={{ color: '#0e347d' }}>{invitacion.listaInvitados[0].nombre}</h2>
                :
                null
              }
            </motion.div>

          </div>

          <motion.div initial={initialOpacityRigth50} whileInView={WIVOpacityRigth50} viewport={{ once: true }} >

            <div className="sectionInv frase">
              <span>Con nuestro amor, la Bendición de Dios y de Nuestras Familias, tenemos el honor de invitarte a nuestra boda</span>
            </div>
          </motion.div>



          <motion.div initial={initialOpacityUp50} whileInView={WIVOpacityUp50} viewport={{ once: true }} >
            <div className="padres">
              <div className="infocompleta">
                <h2>Padres</h2>
                <div className="info">
                  <div className="novio">
                    <h4>Novio</h4>
                    <span>• Samuel Sotelo</span>
                    <span>• Mariela Caro</span>
                  </div>
                  <div className="novia">
                    <h4>Novia</h4>
                    <span className="fallecido">Saturnino Peña</span>
                    <span>• Marlen Gómez</span>
                  </div>
                  <div className="padrinos">
                    <h4>Padrinos</h4>
                    <span>• Ronald  Franco</span>
                    <span>• Marisol Suárez</span>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          <motion.div initial={initialScaleOut13} whileInView={WIVScaleOut13} viewport={{ once: true }} >
            <div className="sectionInv historia">
              <h2>Nuestra historia</h2>
              <span>Después de viajar de norte a sur, de compartir experiencias y de descubrir mil cosas juntos, comenzamos una nueva aventura, ¡NOS CASAMOS!, esta travesía no sería posible celebrarla, sin la compañía de familiares y mejores amigos.</span>
              <span>Han sido años caminando juntos, aprendiendo de nuestros fracasos y victorias, construyendo y afianzando un sentimiento integral lleno de amor, comprensión, apoyo y gratitud de la mano de Dios, es ÉL quien se ha encargado de unir nuestras vidas para  hacer fácil, lo difícil.</span>
              <span>Hemos decidido recibir la Bendición Divina y así confirmar en fe nuestra unión, continuar el trabajo en equipo, para así lograr nuestros objetivos, cumplir nuestros sueños y ser fortaleza para nuestros familiares y amigos.</span>
              <span>Es una gran Bendición poder contar con la presencia de todos aquellos quienes han hecho parte de nuestras vidas, y celebrar juntos este gran amor. Que decir Sí Acepto, sea un motivo de dicha para que todos estemos contentos, disfrutemos, riamos y nos regocijemos del Amor de Dios.</span>
              <span><strong>Que Dios bendiga siempre nuestro Amor. </strong></span>
            </div>
          </motion.div>

          <div className="sectionInv historiaP">
            <div className="content">
              <div className="separador" />
              <motion.div initial={initialOpacityLeft50} whileInView={WIVOpacityLeft50} viewport={{ once: true }} >
                <h4>Primera cita</h4>
              </motion.div>
              <motion.div initial={initialOpacityRigth50} whileInView={WIVOpacityRigth50} viewport={{ once: true }} >
                <span>Nuestra primera cita fue en un Juan Valdez, expresamos nuestros sentimientos, pero preferimos ser amigos, ya que Wilmer tomaba su segundo contrato en cruceros.  Sin embargo, desde un principio sentimos empatía, el uno por el otro, después de varias llamadas y mensajes decidimos darle una oportunidad a nuestros sentimientos, y en su regreso iniciamos a compartir y vivir muchas experiencias con aciertos y desaciertos. </span>
              </motion.div>

              <div className="separador" />
              <motion.div initial={initialOpacityLeft50} whileInView={WIVOpacityLeft50} viewport={{ once: true }} >
                <h4>Primer beso</h4>
              </motion.div>
              <motion.div initial={initialOpacityRigth50} whileInView={WIVOpacityRigth50} viewport={{ once: true }} >
                <span>Después del segundo contrato de Wilmer en cruceros, y de darnos una oportunidad, Mary fue a recibirlo al aeropuerto, a su regreso. Mary no vio cuando Wilmer salió, y él al verla desprevenida le robo un beso.</span>
              </motion.div>

              <div className="separador" />
              <motion.div initial={initialOpacityLeft50} whileInView={WIVOpacityLeft50} viewport={{ once: true }} >
                <h4>Primer "Te amo"</h4>
              </motion.div>
              <motion.div initial={initialOpacityRigth50} whileInView={WIVOpacityRigth50} viewport={{ once: true }} >
                <span>No recordamos cuantos días pasaron para decir Te Amo.    Mary, recuerda que fue ella la primera en decirlo,  lo hizo conscientemente, venciendo el miedo que tenemos las personas, al expresar este sentimiento, debido  al  compromiso que nos genera con la pareja. Continuará ...</span>
              </motion.div>

              <div className="separador" />
              <motion.div initial={initialOpacityLeft50} whileInView={WIVOpacityLeft50} viewport={{ once: true }} >
                <h4>La propuesta</h4>
              </motion.div>
              <motion.div initial={initialOpacityRigth50} whileInView={WIVOpacityRigth50} viewport={{ once: true }} >
                <span>Wilmer hizo la promesa de matrimonio, en el santuario de Fátima en Portugal. Fue muy bonito por la imponencia espiritual del lugar y por todo lo que Wilmer hizo para ese momento.</span>
              </motion.div>

              <div className="separador" />
              <motion.div initial={initialOpacityOnly} whileInView={WIVOpacityOnly} viewport={{ once: true }} >
                <ReactSVG className='rose-logo' src={svgAnillos} ></ReactSVG>
              </motion.div>

            </div>

          </div>

          {/* <div className="sectionInv galeria">
            <h2>Nuestros momentos</h2>
            <ReactSVG className='rose-logo' src={svgFlorDos} ></ReactSVG>
            <span>galeria de fotos</span>
            <br></br>
            <br></br>
            <ImageGallery items={photos} loading={'lazy'} />
          </div> */}

          <div className="fotos">
            <motion.div initial={initialOpacityLeft50} whileInView={WIVOpacityLeft50} viewport={{ once: true }} >
              <div className="foto foto1">
                <img src="https://www.feegosystem.com/srcAltezza/images/invitaciones/WYM/025.jpg" alt="" />
              </div>
            </motion.div>

            <motion.div initial={initialOpacityRigth50} whileInView={WIVOpacityRigth50} viewport={{ once: true }} >
              <div className="foto foto2">
                <img src="https://www.feegosystem.com/srcAltezza/images/invitaciones/WYM/026.jpg" alt="" />
              </div>
            </motion.div>

            <motion.div initial={initialOpacityLeft50} whileInView={WIVOpacityLeft50} viewport={{ once: true }} >
              <div className="foto foto3">
                <img src="https://www.feegosystem.com/srcAltezza/images/invitaciones/WYM/010.jpg" alt="" />
              </div>
            </motion.div>

            <motion.div initial={initialOpacityRigth50} whileInView={WIVOpacityRigth50} viewport={{ once: true }} >
              <div className="foto foto4">
                <img src="https://www.feegosystem.com/srcAltezza/images/invitaciones/WYM/031.jpg" alt="" />
              </div>
            </motion.div>

            <motion.div initial={initialOpacityLeft50} whileInView={WIVOpacityLeft50} viewport={{ once: true }} >
              <div className="foto foto5">
                <img src="https://www.feegosystem.com/srcAltezza/images/invitaciones/WYM/012.jpg" alt="" />
              </div>
            </motion.div>

            <motion.div initial={initialOpacityRigth50} whileInView={WIVOpacityRigth50} viewport={{ once: true }} >
              <div className="foto foto6">
                <img src="https://www.feegosystem.com/srcAltezza/images/invitaciones/WYM/002.jpg" alt="" />
              </div>
            </motion.div>

            <motion.div initial={initialOpacityLeft50} whileInView={WIVOpacityLeft50} viewport={{ once: true }} >
              <div className="foto foto7">
                <img src="https://www.feegosystem.com/srcAltezza/images/invitaciones/WYM/030.jpg" alt="" />
              </div>
            </motion.div>

            <motion.div initial={initialOpacityRigth50} whileInView={WIVOpacityRigth50} viewport={{ once: true }} >
              <div className="foto foto8">
                <img src="https://www.feegosystem.com/srcAltezza/images/invitaciones/WYM/001.jpg" alt="" />
              </div>
            </motion.div>

          </div>



          <motion.div initial={initialOpacityRigth50} whileInView={WIVOpacityRigth50} viewport={{ once: true }} >
            <div className="separador-flor flor-arriba" />
          </motion.div>

          <div className="sectionInv datosEvento">
            <h2>Datos del evento</h2>
            <h4>{fecha}</h4>

            <div className="tarjeta">

              <h4>Ceremonia Católica</h4>

              <motion.div initial={initialOpacityOnly} whileInView={WIVOpacityOnly} viewport={{ once: true }} >
                <div className="iconDatos iglesia">
                  <img src={require('./src/icons/iglesia.png')} alt="" />
                </div>
              </motion.div>

              <div className="info">
                <span><strong>Lugar: </strong> {invitacion.lugarCeremonia}</span>
                <span><strong>Hora: </strong> {horaCeremonia}</span>
                <span>Te recomendamos llegar con tiempo para que realices el respectivo ingreso.</span>

                <div className="ubicacion"><a target={"_blank"} href="https://maps.app.goo.gl/uFnuhNR1YFdBAMDK6">Ver ubicación</a></div>
              </div>
            </div>

            <div className="tarjeta">

              <h4>Recepción</h4>

              <motion.div initial={initialOpacityOnly} whileInView={WIVOpacityOnly} viewport={{ once: true }} >
                <div className="iconDatos copas">
                  <img src={require('./src/icons/copas.png')} alt="" />
                </div>
              </motion.div>

              <div className="info">
                <span><strong>Lugar: </strong> {invitacion.LugarRecepcion}</span>
                <span><strong>Hora: </strong> {horaCelebracion}</span>
                <div className="ubicacion"><a target={"_blank"} href="https://maps.app.goo.gl/uFnuhNR1YFdBAMDK6">Ver ubicación</a></div>
              </div>

            </div>

            <motion.div initial={initialScaleOnly13} whileInView={WIVScaleOnly13} viewport={{ once: true }} >

              <div className="separador-flor flor-arriba" />

              <div className="tarjeta vestuario">
                <h2>Código de vestuario</h2>
                <h4>Etiqueta</h4>

                <span></span>
                <div className="icons">
                  <div className="damas">
                    <img src={require('./src/icons/dress-code-w1.png')} alt="" />
                    <span><strong>Damas: </strong> Omitir colores Blanco, crema, azul y terracota. </span>
                  </div>
                  <div className="caballeros">
                    <img src={require('./src/icons/dress-code-m1.png')} alt="" />
                    <span><strong>Hombres: </strong> Omitir colores azul cobalto o marino.</span>
                  </div>
                </div>
                <div className="info">
                </div>

              </div>


              {invitacion.colorReservadoUno || invitacion.colorReservadoDos ?
                <div className="sectionInv dressCode">
                  <div className="colores">
                    {invitacion.colorReservadoUno ? <div className="colorRes" style={{ backgroundColor: invitacion.colorReservadoUno }} /> : null}
                    <div className="colorRes colorBlanco" style={{ backgroundColor: '#f5ebe0' }} />
                    <div className="colorRes colorBlanco" style={{ backgroundColor: '#fff' }} />
                    {invitacion.colorReservadoDos ? <div className="colorRes" style={{ backgroundColor: invitacion.colorReservadoDos }} /> : null}
                    <div className="colorRes colorBlanco" style={{ backgroundColor: '#457B9D' }} />
                  </div>
                </div>
                :
                null
              }

              <div className="separador-flor flor-abajo" />
            </motion.div>

            <motion.div initial={initialOpacityOnly} whileInView={WIVOpacityOnly} viewport={{ once: true }} >
              <div className="sectionInv lluviaSobres">
                <span>Sus buenos deseos son suficientes para nosotros y en caso de querer hacernos un regalito, este puede ser en efectivo.</span>
                <img src={require('./src/images/lluvia_de_sobres.png')} alt="" />
              </div>
            </motion.div>

          </div>

          <div className="sectionInv confimCount">
            <h2>Confirmanos tu asistencia por favor.</h2>
            <span>No importa si tu respuesta es “si”, “no” o “tal vez” solo asegúrate de confirmar antes del 10 de Junio 2024, ayúdanos a mantener la cuenta de sillas y rebanadas de pastel.  ¡confirma tu asistencia!</span>
          </div>

          {new Date(invitacion.fechaHoraLimiteConfirmar) > new Date() ?
            <div className="sectionInv confirmacionn">

              <div className="sectionInv countDown">
                <Countdown date={new Date(invitacion.fechaHoraLimiteConfirmar)} renderer={(props) => this.rendererCountDown(props)} />
              </div>

              <div className="formAsis">

                {this.state.listaInvitados.length > 0 ?
                  this.state.listaInvitados.map((invitado, index) =>
                    <FormControl className="confAsis">
                      <FormLabel id="demo-row-radio-buttons-group-label" className="nombreInvitado">{invitado.nombre}</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="radioGroupAsis"
                        value={invitado.confirmado}
                        onChange={(e) => { const lista = this.state.listaInvitados; lista[index].confirmado = e.target.value; this.setState({ listaInvitados: lista }) }}
                      >
                        <FormControlLabel className="FormControlLabel" value={1} control={<Radio />} label="Asistiré" />
                        <FormControlLabel className="FormControlLabel" value={3} control={<Radio />} label="No asistiré" />
                        <FormControlLabel className="FormControlLabel" value={2} control={<Radio />} label="Quizá" />
                      </RadioGroup>
                    </FormControl>
                  )
                  :
                  null
                }
                <div className="confirmar" onClick={() => { this.updateConfirmado() }}>Confirmar</div>
              </div>
            </div>

            :
            <div className="sectionInv confirmacionn">
              <div className="formAsis">
                <h2>"No se encuenta disponible confirmar por este medio, por favor comunícate con Wilmer o Mary"</h2>
              </div>
            </div>
          }



          <div className="sectionInv teEsperamos">
            <div className="imgTeEss" />
            <div className="corazon" />


          </div>




          <div className="sectionInv countDown">
            <Countdown date={new Date(invitacion.fechaHoraCeremonia)} renderer={(props) => this.rendererCountDown(props)} />
          </div>

          <motion.div initial={initialOpacityRigth50} whileInView={WIVOpacityRigth50} viewport={{ once: true }} >
            <span className="nota"><strong>Nota: </strong>La zona tiene muchas opciones turísticas, aprovecha para compartir en familia disfrutar al siguiente día algo de nuestra cultura colombiana. No te arrepentirás.</span>
            {/* <span>Te dejamos opciones de lugares en los que puedas cotizar tu hospedaje y el de los tuyos.</span> */}
          </motion.div>

          <div className="sectionInv">
            <h2>Galería de amor</h2>
          </div>

          <div className="sectionInv slideFotos">
            <SlideHeaderInvitacionWM />
          </div>

          <div className="just">

            <motion.div initial={initialOpacityLeft50} whileInView={WIVOpacityLeft50} viewport={{ once: true }} >
              <h4>TE ESPERAMOS AHÍ</h4>
            </motion.div>

            <motion.div initial={initialOpacityRigth50} whileInView={WIVOpacityRigth50} viewport={{ once: true }} >
              <span >“Y por encima de todo esto, revestíos del amor, que es el vínculo de la perfección.“</span>
              <span><strong> Colosenses 3:14</strong></span>
            </motion.div>

          </div>

          <div className="sectionInv header footer" />

        </div>
      )
    }
  }

  render() {


    return (
      <div className="contentContainer">
        <Helmet>
          <title>Altezza - eventos inolvidables</title>
        </Helmet>
        {this.renderLoading()}
      </div>
    )
  }
}
