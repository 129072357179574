/* _________ ScaleOnly8 ____________ */

export const initialScaleOnly8 = {
    scale: 0.8
}

export const WIVScaleOnly8 = {
    scale: 1,
    transition: {
        duration: 2
    }
}

/* _________ ScaleOnly8 ____________ */

export const initialScaleOut13 = {
    scale: 1.1,
    y: 50
}

export const WIVScaleOut13 = {
    scale: 1,
    y: 0,
    transition: {
        duration: 1
    }
}

/* _________ ScaleOnly12 ____________ */

export const initialScaleOnly13 = {
    scale: 0.95,
    x: -20,
    y: -5

}

export const WIVScaleOnly13 = {
    scale: 1.1,
    x: 0,
    transition: {
        duration: 3
    }
}


/* _________ OpacityOnly ____________ */

export const initialOpacityOnly = {
    opacity: 0,
}

export const WIVOpacityOnly = {
    opacity: 1,
    transition: {
        duration: 3
    }
}


/* _________ OpacityRigth ____________ */

export const initialOpacityRigth50 = {
    opacity: 0,
    x: 50
}

export const WIVOpacityRigth50 = {
    opacity: 1,
    x: 0,
    transition: {
        duration: 1
    }
}

/* _________ OpacityRigth ____________ */

export const initialOpacityRigth20 = {
    opacity: 0,
    x: 20
}

export const WIVOpacityRigth20 = {
    opacity: 1,
    x: 0,
    transition: {
        duration: 1
    }
}

/* _________ OpacityLeft ____________ */

export const initialOpacityLeft50 = {
    opacity: 0,
    x: -50
}

export const WIVOpacityLeft50 = {
    opacity: 1,
    x: 0,
    transition: {
        duration: 1
    }
}

/* _________ OpacityLeft ____________ */

export const initialOpacityLeft20 = {
    opacity: 0,
    x: -20
}

export const WIVOpacityLeft20 = {
    opacity: 1,
    x: 0,
    transition: {
        duration: 1
    }
}


/* _________ OpacityRigth ____________ */

export const initialOpacityUp50 = {
    opacity: 0,
    y: -50
}

export const WIVOpacityUp50 = {
    opacity: 1,
    y: 0,
    transition: {
        duration: 1
    }
}