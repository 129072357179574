import React, { Component } from 'react';
import '../App.scss'


class FondoActions extends Component {


    render() {
        return (
            <div className='fondoActions'>
                
            </div>
        );
    }
}

export default FondoActions;
