import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Description } from '@mui/icons-material';
import { useState, useEffect, useRef } from "react";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import './ImportarInvitadosExcel.scss'
import { nuevoMensaje, tiposAlertas } from '../../../inicialized/Toast';
import request from 'superagent';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 290,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ImportarInvitadosExcel = ({ idEvento, parent }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [fileObject, setFileObject] = useState("");
    const [data, setData] = useState([]);
    // const hiddenFileInput = useRef(null);
    useEffect(() => {
        if (fileObject) {
            ExcelRenderer(fileObject, (err, resp) => {
                if (err) {
                    console.log(err);
                } else {
                    const modifyData = resp.rows?.slice(1)?.map((itm, index) => ({
                        //time have a problem
                        idInvitado: itm[0] || "",
                        idInvitacion: itm[1] || "",
                        nombres: itm[2] || "",
                        mensaje: itm[3] || "",
                        principal: itm[4] || 0,
                        celular: itm[5] || 0,
                        parentesco: itm[6] || "",
                        grupoEdad: itm[7] || ""
                    }));

                    setData(modifyData);
                    // resp?.rows?.map()
                    const result = Object.groupBy(modifyData, ({ idInvitacion }) => idInvitacion);
                    setData(result);

                    //console.log(data);
                }
            });
        }
    }, [fileObject]);

    function importInvitacionesExcel() {

        const dataReverse = {}

        Object.keys(data).reverse().map((obj, i) => {
            dataReverse[i+1] = data[obj]
        }
        )

          nuevoMensaje(tiposAlertas.cargando, "Importando invitaciones")
         request
             .post('/responseAltezza/importInvitacionesExcel')
             .send({ data: dataReverse, idEvento:  idEvento}) // sends a JSON post body
             .set('accept', 'json')
             .end((err, res) => {
 
                 if (err) {
                     nuevoMensaje(tiposAlertas.cargadoError, "Error al importar invitaciones, intenta importar de nuevo en unos minutos por favor: " + err);
                 } else {
                     nuevoMensaje(tiposAlertas.cargadoSuccess, "Imvitaciones creadas de forma correcta");
                     setFileObject('')
                     setData([])
                     setOpen(false)
                     parent.getInvitaciones()
                 }
 
             }); 
    }

    function renderInvitaciones() {
        let mensaje = ''
        return Object.keys(data).map((obj, i) =>
            <div className="invitacionImport">
                <span className='idInvImport'>{'Id invitación: ' + (i + 1)}</span>
                {
                    Object.keys(data[obj]).map((inv, idinv) =>
                    {
                        if(data[obj][inv].mensaje != '')mensaje = data[obj][inv].mensaje

                        return (<div className="invitadoImport">
                            <span className={data[obj][inv].principal ? 'invPrincipal nombresImport' : 'nombresImport'} > {data[obj][inv].idInvitado + ': ' + data[obj][inv].nombres}</span>
                            {data[obj][inv].celular ? <span>{data[obj][inv].celular}</span> : null}
                        </div>)}
                    )
                }
                {mensaje && <span className='mensajeInv'>{`Etiqueta: ${mensaje} ` }</span>}
                
                {mensaje = ''}
            </div>
        )
    }


    return (
        <div>
            <div className="addInvitacionIcon" onClick={handleOpen}>
                <span>Importar invitados</span>
                <Description className="icon" />
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="importarInvitadosExcel">

                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Importar invitados desde excel
                        </Typography>

                        {fileObject ?
                            <div className="buttonsImport">
                                <div className="cancelar" onClick={() => { setFileObject(''); setData([]) }}>Cancelar</div>
                                <div className="importar" onClick={() => importInvitacionesExcel()}>Importar</div>
                            </div>
                            :
                            < div className="buttonsImport">

                                <label htmlFor="inputFileImport" className="importar" lab>Seleccionar archivo Excel</label>

                                <input
                                    type="file"
                                    id='inputFileImport'
                                    onChange={(e) => {
                                        setFileObject(e.target.files[0]);
                                    }}
                                    // ref={hiddenFileInput}
                                    style={{ display: "none" }}
                                    accept='.csv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'

                                />
                            </div>
                        }

                        <div className="contentList">
                            {Object.keys(data).length > 0 ?
                                renderInvitaciones()
                                :
                                null
                            }
                        </div>
                    </div>

                </Box>
            </Modal>
        </div >
    );
}

export default ImportarInvitadosExcel;