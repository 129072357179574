const photos = [
    {
      original: 'https://www.feegosystem.com/srcAltezza/images/invitaciones/jyc1042023/Galeria 1.jpg',
      thumbnail: 'https://www.feegosystem.com/srcAltezza/images/invitaciones/jyc1042023/Galeria 1.jpg',
    },
    {
      original: 'https://www.feegosystem.com/srcAltezza/images/invitaciones/jyc1042023/Galeria 2.jpg',
      thumbnail: 'https://www.feegosystem.com/srcAltezza/images/invitaciones/jyc1042023/Galeria 2.jpg',
    },
    {
      original: 'https://www.feegosystem.com/srcAltezza/images/invitaciones/jyc1042023/Galeria 3.jpg',
      thumbnail: 'https://www.feegosystem.com/srcAltezza/images/invitaciones/jyc1042023/Galeria 3.jpg',
    },
    {
      original: 'https://www.feegosystem.com/srcAltezza/images/invitaciones/jyc1042023/Galeria 4.jpg',
      thumbnail: 'https://www.feegosystem.com/srcAltezza/images/invitaciones/jyc1042023/Galeria 4.jpg',
    },
    {
      original: 'https://www.feegosystem.com/srcAltezza/images/invitaciones/jyc1042023/Galeria 6.jpg',
      thumbnail: 'https://www.feegosystem.com/srcAltezza/images/invitaciones/jyc1042023/Galeria 6.jpg',
    }
  ]

  export default photos;