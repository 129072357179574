import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Description } from '@mui/icons-material';
import { useState, useEffect, useRef } from "react";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import './ImportarInvitadosExcel.scss'
import { nuevoMensaje, tiposAlertas } from '../../../inicialized/Toast';
import request from 'superagent';
import ExcelExport from '../../ExcelExport';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 290,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const ExportarInvitadosExcel = ({idEvento}) => {


    const [fileObject, setFileObject] = useState([]);
   
    useEffect(() =>{
        getListaInvitados()
    },[])
   
    function getListaInvitados() {

        request
            .get('/responseAltezza/invitadosXevento/' + idEvento)
            .set('accept', 'json')
            .end((err, res) => {
                if (err) {
                    console.log(err);
                } else {

                    const respuestaLogin = JSON.parse(res.text);
                    setFileObject(respuestaLogin)
                }
            });


    }



    return (
            <div className="addInvitacionIcon">
            <ExcelExport data={fileObject} fileName="Invitados export" />
        </div >
    );
}
 
export default ExportarInvitadosExcel;