import './App.scss';
import { BrowserRouter, Routes , Switch} from 'react-router-dom';
import Route from 'react-router-dom/Route';
import React, { Component, Text } from 'react';
import Evento from './Components/Evento';
import Invitacion from './Components/Invitacion/Invitacion';
import InvitacionJorgeYCata from './Components/Invitacion/InvitacionJorgeYCata';
import { connect } from 'react-redux'
import { saveUsuario, clearUsuario } from '../src/inicialized/localstore/Actions';

function irAweb() {
  window.open("https://altezzaeventos.in/web", "_self")
}
class App extends Component {
  render() {

    if(this.props.usuario.length == 0){
      const UserJSON = window.localStorage.getItem('userAltezza');
          if (UserJSON) {
              const sessionStorageUser = JSON.parse( UserJSON );
              this.props.saveUsuario(sessionStorageUser)
  
          }

    }

    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            {irAweb}
          </Route>
        </Switch>
        <Route exact path="/evento" component={Evento} />
        <Route path="/evento/:id" component={Evento} />
        <Route path="/invitacion/:id/:idInvitado" component={Invitacion} />
        <Route path="/invitacionjorgeycata/:id/:idInvitado" component={InvitacionJorgeYCata} />
      </BrowserRouter>
    );
  }

  

  
}

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario
  }
}


const mapDispatchToProps = {
  saveUsuario: saveUsuario,
  clearUsuario: clearUsuario
}

export default connect(mapStateToProps,mapDispatchToProps)(App);

